<template>
  <iColumn>
    <iSubHeading>Queue</iSubHeading>

    <iRow v-for="entry in queue" :key="entry.timestamp" vertical-align="middle">
      <iLabel>{{ users[entry.userId].name }} {{ entry.timestamp }} </iLabel>
      <iColumn
        v-if="entry.userId == myUserId"
        width="hug"
      >
        <iButton
          @click="dropFromQueue(entry.timestamp)"
          size="small"
        >
          Drop
        </iButton>
      </iColumn>
      <iColumn
        width="hug"
        v-if="optionKeyDown && metaKeyDown"
      >
        <iButton
          @click="kickFromQueue(entry.userId, entry.timestamp)"
          size="small"
        >
          Kick
        </iButton>
      </iColumn>
    </iRow>
    <iColumn
      width="hug"
      v-if="optionKeyDown"
    >
      <iButton
        @click="jumpQueue()"
      >
        Jump
      </iButton>
    </iColumn>
    <iColumn
      width="hug"
      v-else
    >
      <iButton
        @click="joinQueue()"
      >
        Join
      </iButton>
    </iColumn>
  </iColumn>
</template>

<script>
/*

*/
import { mapActions, mapState, mapWritableState } from "pinia";
import { useStateStore } from "@/stores/state-store";
import { useGeneralStore } from "@/stores/general-store";

export default {
  name: "Queue",
  components: {
  },
  props: {
  },
  data() {
    return {
      optionKeyDown: false,
      controlKeyDown: false,
      metaKeyDown: false,
    };
  },
  computed: {
    ...mapState(useGeneralStore, [
      "myUserId",
    ]),
    ...mapState(useStateStore, [
      "users",
      "queue",
      "joinLoading",
      "kickLoading",
      "dropLoading",
    ]),
    filteredPrs() {
    },
  },
  created() {
  },
  beforeUnmount() {
  },
  methods: {
    ...mapActions(useStateStore, [
      "joinQueue",
      "jumpQueue",
      "dropFromQueue",
      "kickFromQueue",
    ]),
    keyUp(event) {
      if (event.key === "Control") {
        this.controlKeyDown = false;
      } else if (event.key === "Meta") {
        this.metaKeyDown = false;
      } else if (event.key === "Alt") {
        this.optionKeyDown = false;
      }
    },
    keyDown(event) {
console.log(event.key);
      if (event.key === "Control") {
        this.controlKeyDown = true;
      } else if (event.key === "Meta") {
        this.metaKeyDown = true;
      } else if (event.key === "Alt") {
        this.optionKeyDown = true;
      }
    },
  },
  created() {
    document.addEventListener("keydown", this.keyDown);
    document.addEventListener("keyup", this.keyUp);
  },
  unmounted() {
    document.removeEventListener("keydown", this.optionHandler);
  },
};
</script>
