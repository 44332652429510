import { defineStore } from "pinia";
import { $axios } from "@bloglovin/vue-framework";

export const useStateStore = defineStore("state-store", {
  state: () => ({
    queue: [],
    users: {},
    activeUserId: 0,
    timestampLastUpdate: 0,
    dropLoading: {},
    kickLoading: {},
    joinLoading: false,
  }),
  getters: {
  },
  actions: {
    startPollingForState() {
      this.apiGet(`/state`, {}, this.updateState);
    },
    getMessageFromError(error) {
      return "something went wrong";
    },
    updateState(data) {
      this.queue = data.state.queue;
      this.users = data.state.users;
      this.activeUserId = data.state.activeUserId;
      this.timestampLastUpdate = data.state.timestampLastUpdate;
    },
    post(uri, data, finallyCallback) {
      this.apiPost(uri, data, this.updateState, null, finallyCallback);
    },
    kickFromQueue(userId, timestamp) {
      if (this.kickLoading[timestamp]) {
        return;
      }
      this.kickLoading[timestamp] = true;
      this.post("/queue/kick", { user_id: userId, timestamp }, () => this.kickLoading[timestamp] = false);
    },
    dropFromQueue(timestamp) {
      if (this.dropLoading[timestamp]) {
        return;
      }
      this.dropLoading[timestamp] = true;
      this.post("/queue/drop", { timestamp }, () => this.dropLoading[timestamp] = false);
    },
    jumpQueue() {
      if (this.joinLoading) {
        return;
      }
      this.joinLoading = true;
      this.post("/queue/jump", {}, () => this.joinLoading = false);
    },
    joinQueue() {
      if (this.joinLoading) {
        return;
      }
      this.joinLoading = true;
      this.post("/queue/join", {}, () => this.joinLoading = false);
    },
  },
});
